<template>
  <div
    v-if="hasUnpaidRegistrations"
    class="alert"
    :class="{
      'alert-danger': forcePayUnpaid,
      'alert-warning': !forcePayUnpaid
    }"
  >
    <h4>You have unpaid registrations</h4>
    <p v-if="forcePayUnpaid" class="lead">
      Due to unpaid registrations you will not be able to schedule days or sign
      up in classes.
    </p>
    <p v-else class="lead">
      Due to unpaid registrations you should pay these before registering for
      more classes. You will soon be forced to pay unpaid registrations before
      signing up for new classes or days.
    </p>
    <cs-loading v-if="$wait.waiting('loading-unpaids')">
      Loading current unpaid Registrations
    </cs-loading>
  </div>
</template>

<script>

export default {
  name: 'Unpaids',
  data () {
    return {
      unpaidTotal: 0
    }
  },
  props: {
    hasUnpaidRegistrations: {
      type: Boolean,
      default: false
    },
    forcePayUnpaid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
  }
}
</script>

<style scoped></style>
